import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FlipCards from "./flipcards";

const Referententätigkeit = () => {
  const data = useStaticQuery(graphql`
    query Section3Query {
      allFile(
        filter: { sourceInstanceName: { eq: "content" }, name: { eq: "about" } }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                section_3 {
                  flipcards {
                    backHeading
                    backText
                    frontImage {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  heading
                }
              }
            }
          }
        }
      }
    }
  `);

  const markdown =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter.section_3;

  return (
    <section
      id="referententätigkeit"
      className="about-text col-start-2 col-end-3 row-start-3 row-end-4"
    >
      <h2 dangerouslySetInnerHTML={{ __html: markdown.heading }} />

      <FlipCards flipcards={markdown.flipcards} />
    </section>
  );
};

export default Referententätigkeit;
