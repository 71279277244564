import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import FlipCard from "./flipcard";
import BackCard from "./backcard";

const Flipcards = ({ flipcards }) => (
  <div className="grid-center w-full flex flex-col items-center md:grid md:gap-14 md:grid-flow-row md:grid-cols-2">
    {flipcards.map((flipcard, index) => (
      <div
        key={index}
        className="flex items-center justify-center w-64 h-64 xl:w-75 xl:h-75"
      >
        <FlipCard
          front={
            <Img
              fluid={flipcard.frontImage.childImageSharp.fluid}
              className="w-full"
              alt={`${flipcard.backHeading} logo`}
            />
          }
          back={
            <BackCard heading={flipcard.backHeading} text={flipcard.backText} />
          }
        />
      </div>
    ))}
  </div>
);

Flipcards.propTypes = {
  flipcards: PropTypes.array.isRequired
};

export default Flipcards;
