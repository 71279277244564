import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Einleitung = () => {
  const data = useStaticQuery(graphql`
    query Section1Query {
      allFile(
        filter: { sourceInstanceName: { eq: "content" }, name: { eq: "about" } }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                section_1 {
                  content
                  heading
                }
              }
            }
          }
        }
      }
      file(relativePath: { eq: "unterschrift.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const markdown =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter.section_1;

  const formattedContent = markdown.content
    .split("\n\n")
    .map(
      paragraph =>
        `<p class='text-brand-black text-justify font-light text-sm leading-7 mb-5'>${paragraph.replace(
          /\n/g,
          "<br>"
        )}</p>`
    )
    .join("");

  return (
    <section
      id="einleitung"
      className="about-text flex flex-col items-stretch  col-start-2 col-end-3 row-start-1 row-end-2"
    >
      <h2>{markdown.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: formattedContent }}></div>
      <div className="mt-13">
        <div className="w-50">
          <Img fluid={data.file.childImageSharp.fluid} alt="Signature" />
        </div>
        <span className="block -mt-3 mb-0">Petra Pflug</span>
      </div>
    </section>
  );
};

export default Einleitung;
