import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Timeline from "./timeline";

const Zertifizierungen = () => {
  const data = useStaticQuery(graphql`
    query Section2Query {
      allFile(
        filter: { sourceInstanceName: { eq: "content" }, name: { eq: "about" } }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                section_2 {
                  table {
                    year_table {
                      left
                      right
                    }
                    year
                  }
                  heading
                }
              }
            }
          }
        }
      }
    }
  `);

  const markdown =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter.section_2;

  const formattedHeading = markdown.heading.replace(/\n/g, " <br> ");

  return (
    <section
      id="zertifizierungen"
      className="about-text col-start-2 col-end-3 row-start-2 row-end-3"
    >
      <h2 dangerouslySetInnerHTML={{ __html: formattedHeading }} />

      <div>
        {markdown.table.map((item, index) => (
          <Timeline
            key={`${index}-${item.year}`}
            year={item.year}
            items={item.year_table}
          />
        ))}
      </div>
    </section>
  );
};

export default Zertifizierungen;
