import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion, useViewportScroll, useTransform } from "framer-motion";

function SidebarItem({ title, id }) {
  const { scrollY } = useViewportScroll();

  const [elementTop, setElementTop] = useState(0);
  const [elementBottom, setElementBottom] = useState(0);

  useEffect(() => {
    const el = document.querySelector(id);

    const setValues = () => {
      setElementTop(el.offsetTop);
      const bottom = el.offsetTop + el.offsetHeight;
      setElementBottom(bottom);
    };
    setValues();
    document.addEventListener("load", setValues);
    window.addEventListener("resize", setValues);

    return () => {
      document.removeEventListener("load", setValues);
      window.removeEventListener("resize", setValues);
    };
  });

  const opacity = useTransform(
    scrollY,
    [elementTop - 30, elementTop, elementBottom - 100, elementBottom + 10],
    [0, 1, 1, 0]
  );

  const translateY = useTransform(
    scrollY,
    [elementTop - 30, elementTop, elementBottom - 100, elementBottom + 10],
    ["-200%", "0%", "0%", "200%"],
    {
      clamp: false
    }
  );

  const scaleX = useTransform(
    scrollY,
    [elementTop - 30, elementTop, elementBottom - 100, elementBottom + 10],
    [0.5, 1, 1, 0.5]
  );

  return (
    <li className="border-l-2 border-brand-gray">
      <a
        className="py-5 inline-block block outline-none no-underline"
        href={`/about/${id}`}
      >
        <div className="relative slider left-3neg px-5">
          <div className="pl-2-5 text-black font-normal">{title}</div>
          <motion.div
            style={{ opacity, translateY, scaleX }}
            className={"absolute w-4px bg-brand-orange top-0 left-0 h-full"}
            transition={{
              type: "spring",
              damping: 100,
              stiffness: 100
            }}
          />
        </div>
      </a>
    </li>
  );
}

function Sidebar() {
  return (
    <aside className="sticky top-50 pt-screen-40 ml-26">
      <ul className="flex flex-col mt-0">
        <SidebarItem title="Einleitung" id="#einleitung" />
        <SidebarItem title="Zertifizierungen" id="#zertifizierungen" />
        <SidebarItem title="Referententätigkeit" id="#referententätigkeit" />
        <SidebarItem title="Werdegang" id="#werdegang" />
      </ul>
    </aside>
  );
}

SidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default Sidebar;
