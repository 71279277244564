import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function LegalLink({ className = "text-white" }) {
  return (
    <div
      className={`${className} text-xs lg:text-sm absolute bottom-20 inset-x-0 text-center`}
    >
      <Link to="/legal">Impressum | Datenschutz</Link>
    </div>
  );
}

LegalLink.PropTypes = { className: PropTypes.string };

export default LegalLink;
