import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

function FlipCard({ front, back }) {
  const [flipped, toggleFlip] = useState(false);
  let t;

  const handleFlip = () => {
    if (flipped === false) {
      toggleFlip(true);
    } else {
      clearTimeout(t);
      t = setTimeout(() => {
        toggleFlip(!flipped);
      }, 7000);
    }
  };

  useEffect(() => {
    if (flipped) {
      clearTimeout(t);
      t = setTimeout(() => {
        toggleFlip(!flipped);
      }, 7000);
    }
  });

  const domRef = useRef();

  useEffect(() => {
    // Only flip on scroll for screens with width < md i.e. 768px
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => toggleFlip(entry.isIntersecting));
        });

        observer.observe(domRef.current);

        return () => observer.unobserve(domRef.current);
      }
      return null;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={domRef} className="flip-container" onMouseEnter={handleFlip}>
      <div className={`${flipped ? "flipped" : ""} flip_box`}>
        <div className="front p-4 border-brand-orange-light border-dotted border-2 flex items-center justify-center">
          {front}
        </div>
        <div className="back bg-brand-orange-light flex items-center justify-center">
          {back}
        </div>
      </div>
    </div>
  );
}

FlipCard.propTypes = {
  front: PropTypes.node.isRequired,
  back: PropTypes.node.isRequired
};

export default FlipCard;
