import React from "react";
import PropTypes from "prop-types";

function BackCard({ heading, text }) {
  return (
    <div className="text-center">
      <h2 className="text-white capitalize font-bold text-center mt-5 mb-0">
        {heading}
      </h2>
      <p className="mb-2-5 py-5 px-5-75 text-center font-normal text-white leading-5">
        {text}
      </p>
    </div>
  );
}

BackCard.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default BackCard;
