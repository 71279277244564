import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Sidebar from "../components/sidebar";
import BouncingMouse from "../components/bouncingmouse";
import ParallaxTitle from "../components/parallaxtitle";
import MultiBackground from "../components/multibackground";

import Einleitung from "../components/einleitung";
import Zertifizierungen from "../components/zertifizierungen";
import Referententätigkeit from "../components/referententätigkeit";
import Werdegang from "../components/werdegang";

import LegalLink from "../components/legal_link";

function AboutPage({ data }) {
  const markdown = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title="Über mich" />

      <div>
        <MultiBackground className="hero-face">
          <ParallaxTitle title={markdown.title} intro={markdown.intro} />
          <BouncingMouse />
        </MultiBackground>

        <div className="about-bg">
          <div className="about mx-auto max-w-6xl grid grid-flow-row lg:grid-cols-about lg:gap-4 mb-10">
            <div className="hidden lg:block col-start-1 col-end-2 row-start-1 row-end-5">
              <Sidebar />
            </div>
            <Einleitung />
            <Zertifizierungen />
            <Referententätigkeit />
            <Werdegang />
          </div>
          <LegalLink className="text-gray-600" />
        </div>
      </div>
    </Layout>
  );
}

export const data = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "about" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              intro
            }
          }
        }
      }
    }
  }
`;

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;
