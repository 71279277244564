import PropTypes from "prop-types";
import React from "react";

function Timeline({ year, items, heading = "" }) {
  return (
    <div>
      <table className="relative table-fixed w-full">
        <thead>
          <tr>
            <th
              colSpan="2"
              className="pt-8 sticky top-0 bg-white pb-1 w-full text-brand-orange font-hairline transition duration-300 ease-in-out"
            >
              <div className="my-1-25 text-2xl md:text-3-5xl">{year}</div>
              <h3
                className={`${
                  heading ? "block" : "hidden"
                } text-lg text-black font-hairline my-0`}
              >
                {heading}
              </h3>
            </th>
          </tr>
        </thead>
        {items && (
          <tbody className="text-brand-black font-light">
            {items.map((item, index) => (
              <tr className="text-xs sm:text-sm" key={`${index}`}>
                <td className="py-5 px-3 text-right">{item.left}</td>
                <td className="border-l border-brand-black px-3 text-left">
                  {item.right}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

Timeline.propTypes = {
  year: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  heading: PropTypes.string
};

export default Timeline;
